
import {defineComponent} from "vue";
import {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonInput,
    IonLabel,
    IonTextarea,
    IonButton,
    IonImg, IonFab, IonFabButton, IonIcon, toastController, loadingController, alertController, IonDatetime
} from "@ionic/vue";
import {InAppBrowser} from "@awesome-cordova-plugins/in-app-browser";
import {eyeOutline, linkOutline, saveOutline, trashOutline} from "ionicons/icons";
const imagesForUploading: Array<any> = [];
import {HDate} from '@hebcal/core'
import moment from "moment";

export default defineComponent({
    name: 'InvitationEdit',
    inject: ['baseUrl'],
    components: {
        IonPage,
        IonContent,
        IonList,
        IonItem,
        IonInput,
        IonLabel,
        IonTextarea,
        IonButton,
        IonImg,
        IonFab,
        IonFabButton,
        IonIcon,
        IonDatetime
    },
    computed: {
        maxEventDate(){
            return moment().add(2, 'years').format('YYYY-MM-DD')
        },
        minEventDate(){
            return moment().add(14, 'days').format('YYYY-MM-DD')
        },
        invitationInfoId: {
            get() {
                return this.$store.state.invitationInfo.id
            },
            set(value) {
                this.$store.commit('invitationInfoId', value)
            }
        },
        title: {
            get() {
                return this.$store.state.invitationInfo.title
            },
            set(value) {
                this.$store.commit('invitationInfoTitle', value)
            }
        },
        subtitle: {
            get() {
                return this.$store.state.invitationInfo.subtitle
            },
            set(value) {
                this.$store.commit('invitationInfoSubtitle', value)
            }
        },
        eventDate: {
            get() {
                return this.$store.state.invitationInfo.eventDate
            },
            set(value) {
                const hdate = new HDate(new Date(value))
                this.$store.commit('invitationInfoEventDate', value)
                this.$store.commit('invitationInfoHebrewDate', hdate.renderGematriya())
            }
        },
        hebrewDate: {
            get() {
                return this.$store.state.invitationInfo.hebrewDate
            },
            set(value) {
                this.$store.commit('invitationInfoHebrewDate', value)
            }
        },
        text: {
            get() {
                return this.$store.state.invitationInfo.text
            },
            set(value) {
                this.$store.commit('invitationInfoText', value)
            }
        },
        locationName: {
            get() {
                return this.$store.state.invitationInfo.locationName
            },
            set(value) {
                this.$store.commit('invitationInfoLocationName', value)
            }
        },
        location: {
            get() {
                return this.$store.state.invitationInfo.location
            },
            set(value) {
                this.$store.commit('invitationInfoLocation', value)
            }
        },
        address: {
            get() {
                return this.$store.state.invitationInfo.address
            },
            set(value) {
                this.$store.commit('invitationInfoAddress', value)
                const urlEncodedAddress = encodeURI(value)
                const wazeLink = `https://waze.com/ul?q=${urlEncodedAddress}&navigate=yes`
                const mapLink = `https://www.google.com/maps/search/?api=1&query=${urlEncodedAddress}`
                this.$store.commit('invitationInfoWazeLink', wazeLink)
                this.$store.commit('invitationInfoMapLink', mapLink)
            }
        },
        mapLink: {
            get() {
                return this.$store.state.invitationInfo.mapLink
            },
            set(value) {
                this.$store.commit('invitationInfoMapLink', value)
            }
        },
        wazeLink: {
            get() {
                return this.$store.state.invitationInfo.wazeLink
            },
            set(value) {
                this.$store.commit('invitationInfoWazeLink', value)
            }
        },
        rsvpTitle: {
            get() {
                return this.$store.state.invitationInfo.rsvpTitle
            },
            set(value) {
                this.$store.commit('invitationInfoRsvpTitle', value)
            }
        },
        rsvpText: {
            get() {
                return this.$store.state.invitationInfo.rsvpText
            },
            set(value) {
                this.$store.commit('invitationInfoRsvpText', value)
            }
        },
        images: {
            get() {
                if(this.$store.state.invitationInfo){
                    return this.$store.state.invitationInfo.images
                }
                return null
            },
            set(media) {
                this.$store.commit('invitationInfoAddImages', media)
            }
        },
        rsvpImages: {
            get() {
                if(this.$store.state.invitationInfo){
                    return this.$store.state.invitationInfo.rsvpImages
                }
                return null
            },
            set(media) {
                this.$store.commit('invitationInfoAddRsvpImages', media)
            }
        },
        selectedInvitationDesign(){
            return this.$store.getters.selectedInvitationDesign
        }
    },
    data(){
        return {
            icons: {
                eyeOutline,
                saveOutline,
                linkOutline,
                trashOutline
            },
            imagesForUploading
        }
    },
    methods: {
        loadInvitation() {
            this.$store.dispatch('invitationInfoLoad').catch(() => ({}))
        },
        async save() {

            await this.uploadImages()

            const loading = await loadingController.create({
                message: "שומר הזמנה"
            })

            await loading.present()

            if(await this.$store.dispatch('invitationInfoSave')){

                await loading.dismiss()

                const toast = await toastController.create({
                    color: "success",
                    message: "ההזמנה נשמרה בהצלחה",
                    duration: 2000,
                    position: 'middle'
                })

                await toast.present()

                await toast.onDidDismiss()

                await toast.present()

                await this.$store.dispatch('loadData')

                if(!this.$store.getters.invitees || this.$store.getters.invitees.length === 0){
                    await this.$router.replace('/tabs/rsvp')
                }
            }else{
                await loading.dismiss()

                await (await alertController.create({
                    message: "הזמנה לא נשמרה, יש למלא תאריך, כותרת, תוכן ההזמנה ומיקום האירוע.",
                    buttons: [
                        "הבנתי"
                    ]
                })).present()
            }
        },
        async uploadImages(){

            if(this.imagesForUploading && this.imagesForUploading.length){

                const loading = await loadingController.create({
                    message: "מעלה תמונות"
                })

                await loading.present()

                const formData = new FormData()
                for (let i = 0; i < this.imagesForUploading.length; i++) {
                    const file = this.imagesForUploading[i]
                    formData.append('files[]', file.file, file.file.name)
                    formData.append('type[]', file.type)
                }
                try{
                    const {data} = await this.$http({
                        method: 'post',
                        url: `invitation/${this.invitationInfoId}/media`,
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })

                    if(data && data.media){
                        if(Array.isArray(data.media)){
                            data.media.forEach((m: any) => {
                                if(m.pivot && m.pivot.type === 'rsvpImage'){
                                    this.rsvpImages = m
                                }else{
                                    this.images = m
                                }
                            })
                        }else{
                            if(data.media.pivot && data.media.pivot.type === 'rsvpImage'){
                                this.rsvpImages = data.media;
                            }else{
                                this.images = data.media;
                            }
                        }
                    }

                    this.imagesForUploading = [];
                }catch (e){
                    console.warn(e)
                }
                await loading.dismiss()

            }
        },
        loadImageForUpload(e: any, type = 'image') {
            if (e && e.target && e.target.files) {
                for (let i = 0; i < e.target.files.length; i++) {
                    this.imagesForUploading.push({
                        file: e.target.files[i],
                        type
                    })
                }
            }
        },
        preview(){
            InAppBrowser.create(`${this.baseUrl}/invitation/${this.invitationInfoId}`);
        },
        linkPreview(link: string){
            InAppBrowser.create(link);
        },
        async removeImage(images: Array<string>, index: number){
            const imageToRemove = images[index]
            const loading = await loadingController.create({
                message: 'מוחק...'
            })
            await loading.present()

            const response = await this.$http.post(`invitation/${this.invitationInfoId}/media/remove`, {
                image: imageToRemove
            }, {timeout: 30000})

            await loading.dismiss()

            if(response && response.status){
                if(response.status === 200){
                    images.splice(index, 1)
                    await (await toastController.create({
                        message: 'תמונה נמחקה בהצלחה',
                        color: 'success',
                        duration: 1500
                    })).present()
                }else{
                    await (await toastController.create({
                        message: 'התרחשה תקלה, התמונה לא נמחקה.',
                        color: 'danger',
                        duration: 1500
                    })).present()
                }
            }
        }
    },
    mounted() {
        this.loadInvitation()
    }
})
